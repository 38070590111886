.titlebar {
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    display: flex;
    height: calc(38px - 16px);
    justify-content: space-between;
    padding: 8px 16px;
}

.titlebar-item > * {
    position: absolute;
    transform: translateX(-50%);
}

.titlebar-item-right > * {
    transform: translateX(-100%);
}
