.solution {
    border-radius: 4px;
    color: #c3e88d;
    display: inline;
    padding: 0 4px;
    text-align: right;
}

.solution:hover {
    background-color: #c3e88d;
    color: black;
    cursor: pointer;
}

.solution:hover:active { filter: brightness(90%); }

.solution-wrapper {
    display: block;
    text-align: right;
}
