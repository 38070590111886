.divider {
    cursor: col-resize;
    user-select: none;
}

.divider > div {
    background-color: rgba(255, 255, 255, 0.1);
    height: 100%;
    margin: 0 8px;
    width: 2px;
}

.divider:hover > div { background-color: rgba(255, 255, 255, 0.2); }

.pane {
    min-width: 100px;
}

.pane-left {
    margin: 16px 8px 16px 16px;
    width: 75%;
}

.pane-right {
    margin: 16px 16px 16px 8px;
    width: 25%;
}

.split-pane {
    display: flex;
    min-height: 100%;
    width: 100%;
}
