#editor-highlighting, #editor-typing { grid-area: 1 / 1 / 2 / 2; }

#editor-highlighting, #editor-highlighting > code, #editor-typing {
    border: 0;
    font-family: 'Roboto Mono';
    font-size: 14px;
    line-height: 1.6em;
    margin: 0;
    outline: none;
    overflow-wrap: break-word;
    padding: 0;
    white-space: pre-wrap;
    width: 100%;
}

#editor-typing {
    background: transparent;
    caret-color: white;
    color: transparent;
    resize: none;
    z-index: 1;
}

#editor-wrapper {
    display: grid;
    min-height: 100%;
}
